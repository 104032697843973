/* ContactUs.css */
.contact-us-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fa;
    padding: 40px;
    border-radius: 12px;
    max-width: 600px;
    margin: 40px auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .contact-us-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .contact-us-form {
    width: 100%;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    font-size: 1rem;
    color: #555;
    margin-bottom: 8px;
    display: block;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: border-color 0.3s;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  textarea {
    resize: none;
    height: 150px;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 8
  }