.first-container{

}

.second-container{
padding-top: "5rem"

}


.carouselImage{
    display: grid;
    padding: 2rem !important;
    width: 30rem !important;
    margin: 0 auto;
    align-items: center;
}

.carousel-control-next-icon{
    /* top:50% !important; */
    background-image:url("./right-arrow.png") !important;
    /* <a href="https://www.flaticon.com/free-icons/next" title="next icons">Next icons created by Roundicons - Flaticon</a> */
}

.carousel-control-prev-icon{
    background-image:url("./left-arrow.png") !important;
    /* <a href="https://www.flaticon.com/free-icons/back" title="back icons">Back icons created by Roundicons - Flaticon</a> */
}

.carousel-control-next, .carousel-control-prev {
    /* top:50% !important; */
    align-items:unset   !important;
    top: 20rem;
}

.buttons > * {
    margin: 1rem;

    
}

.MuiButton-root{
    box-shadow: unset !important;
    border-radius: unset !important;
    

}



.MuiButton-contained-custom{
    background-color: #6c584c !important;
}


/* Add this to your stylesheet */
.variant-selector {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Adjust minmax values as needed */
    gap: 0.5rem;
    align-items: center;
    border-radius: 5rem;
  }
  
  .variant-buttons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* Adjust this to fit the buttons nicely */
    gap
    : 0.5rem; /* Space between buttons */
}

/* For the price display on small screens */
@media (max-width: 768px) { /* Adjust breakpoint as necessary */
  .variant-selector {
    grid-template-columns: 1fr; /* Stacks elements in a single column */
  }

  /* Stacks buttons in a single column */
  /* .variant-buttons {
    grid-template-columns: 3fr; 
  } */

  .price-display-flex {
    display: grid;
    grid-template-columns: 1fr; /* Stacks price information in a single column */
    gap: 0.5rem;
  }
}
  

.variant-button:hover {
  background-color: #7f5539 !important; /* Desired hover background color */
  color: white; /* Desired hover text color */
}